import React, { useEffect, useState } from "react";
import "./App.css";
import Logo from "../src/components/Logo";
import Particles from "../src/components/Particles";
import Partners from "../src/components/Partners";
import Confetti from "react-confetti";

export default function App() {
  const [tempo, setTempo] = useState(null);
  const [contagem, setContagem] = useState(false);
  const [fireworksVisible, setFireworksVisible] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);


  useEffect(() => {
    const tempoAtual = Date.now();

    fetch("https://api.amadeira.pt/api/gjp/counter.php", {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.tempo === 0) {
          setTempo(48*3600);
        } else {
          const dataServidor = new Date(data.tempo);
          const horaServidor = dataServidor.getTime();
          const horasTotais = 48*3600 * 1000;

          if (
            Math.floor((horasTotais - (tempoAtual - horaServidor)) / 1000) >= 0
          ) {
            setTempo(
              Math.floor((horasTotais - (tempoAtual - horaServidor)) / 1000)
            );
          }
          else {
            setTempo(0)
          }

          if (
            !contagem &&
            Math.floor((horasTotais - (tempoAtual - horaServidor)) / 1000) > 0
          ) {
            setContagem(true);

            let contador = Math.floor(
              (horasTotais - (tempoAtual - horaServidor)) / 1000
            );

            const interval = setInterval(() => {
              contador--;
              setTempo(contador);

              if (contador === 0) {
                setFireworksVisible(true);
                clearInterval(interval);
              }
            }, 1000);
          }
        }
      })
      .catch((error) => {
        console.error("Erro ao buscar a data e hora do servidor:", error);
      });
  }, [contagem]);

  const iniciarContagem = () => {
    if (!contagem) {
      setContagem(true);

      const dataAtual = new Date();
      const ano = dataAtual.getFullYear();
      const mes = String(dataAtual.getMonth() + 1).padStart(2, "0");
      const dia = String(dataAtual.getDate()).padStart(2, "0");
      const hora = String(dataAtual.getHours()).padStart(2, "0");
      const minutos = String(dataAtual.getMinutes()).padStart(2, "0");
      const segundos = String(dataAtual.getSeconds()).padStart(2, "0");
      const microssegundos = String(dataAtual.getMilliseconds()).padStart(
        6,
        "0"
      );

      const formatoDataHora = `${ano}-${mes}-${dia} ${hora}:${minutos}:${segundos}.${microssegundos}`;

      fetch("https://api.amadeira.pt/api/gjp/counter.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: 1, tempo: formatoDataHora }),
      })
        .then((response) => response.text())
        .then(() => {
          console.log("Tempo inserido com sucesso na base de dados!");
        })
        .catch((error) => {
          console.error("Erro:", error);
        });

      let contador = tempo;
      const interval = setInterval(() => {
        contador--;
        setTempo(contador);

        if (contador === 0) {
          clearInterval(interval);
        }
      }, 1000);
    }
  };

  const formatarTempo = (segundos) => {
    const horasRestantes = Math.floor(segundos / 3600);
    const minutosRestantes = Math.floor((segundos % 3600) / 60);
    const segundosRestantes = segundos % 60;

    const formatoHora = (valor) => (valor < 10 ? `0${valor}` : valor);

    return `${formatoHora(horasRestantes)}:${formatoHora(
      minutosRestantes
    )}:${formatoHora(segundosRestantes)}`;
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div>
      <Logo />
      <Particles />
      {tempo !== null && contagem !== null && (
        <div className="flex flex-col items-center gap-8">
          <label className="lg:text-4xl text-3xl" id="tempo-restante">
            TEMPO RESTANTE:
          </label>
          <label
            className="lg:text-[200px] md:text-[150px] text-[80px]"
            id="tempo"
          >
            {formatarTempo(tempo)}
          </label>
          <div id="botao-container">
            <button
              className="bg-white text-customRed h-16 w-40 border-none cursor-pointer text-2xl font-semibold text-center rounded-xl"
              onClick={iniciarContagem}
              id={contagem || tempo === 0 ? "botao-iniciar-oculto" : "botao-iniciar"}
            >
              INICIAR
            </button>
          </div>
          {fireworksVisible && (
              <>
                <Confetti
                    width={windowWidth}
                    height={windowHeight}
                />
              </>
          )}
        </div>
      )}
      <Partners />
    </div>
  );
}
